import BaseService from '@/services/BaseService'

class WholesaleService extends BaseService {
  constructor() {
    super()
  }

  async wholesale(wholesalable_id, data = {}) {
    this.endPoint = `api/v2/wholesalable/${wholesalable_id}/wholesale-price`
    return this.post(data)
  }

  async deleteWholesale(wholesalable_id, data) {
    this.endPoint = `api/v2/wholesalable/${wholesalable_id}/wholesale-price`
    return this.rawDelete(data)
  }
}
export default new WholesaleService()