// node_modules dependencies
import Component from 'vue-class-component'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import filter from 'lodash/filter'
import join from 'lodash/join'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import upperCase from 'lodash/upperCase'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import { Watch } from 'vue-property-decorator'

// Base Page
import BasePage from '@/pages/Base'

// Services
import SettingsService from '@/services/GlobalSettings'
import CurrencyService from '@/services/Currency'
import CountryService from '@/services/Country'
import CourierService from '@/services/Courier'
import WholesaleService from '@/services/WholesalePrice'
import DomesticService from '@/services/DomesticShipping'
import CityService from '@/services/City'
import ProvinceService from '@/services/Province'
import RulesService from '@/services/Rules'
import UserSettings from '@/services/UserSettings'
import { includes, truncate } from 'lodash'
import { settings } from 'accounting'
import { param } from 'jquery'

@Component({})
export default class LogisticIndex extends BasePage {
  constructor() {
    super()
  }
  //enable role
  enable_rate: any = {
    air : false,
    ocean : false,
    air_dtd : false,
    ocean_dtd : false,
    official_trucking : false,
    unofficial_trucking : false
  }
  member: any = {
    loading: false,
    items: []
  }

  //insurance
  insurance : boolean = false

  //wholesale
  wholesale : any ={
    air_cargo : false,
    ocean_cargo : false,
    air_dtd : false,
    ocean_dtd : false,
    trucking_dtd : false,
    trucking_cargo : false
  }
  dataWholesale: any = {
    air_cargo: [],
    ocean_cargo: [],
    air_dtd: [],
    ocean_dtd: [],
    trucking_dtd : [],
    trucking_cargo : []
  }
  //wholesalable_id
  wholesale_id_cbm: string
  wholesale_id_weight: string
  wholesale_id_cbmdtd: string
  wholesale_id_weightdtd: string
  wholesale_id_trucking: string
  wholesale_id_truckingdtd: string

  //id temp wholesale
  tempWholesale : any = {
    air_cargo: [],
    ocean_cargo: [],
    air_dtd: [],
    ocean_dtd: [],
    trucking_dtd : [],
    trucking_cargo : []
  }

  // Table
  global_var_country_id: string
  id_routes_deleted: object[] = []
  tableHeaders: object[] = [
    {
      text: 'Country Name',
      align: 'left',
      sortable: false,
      value: 'id'
    }
  ]
  tableItems: any[] = []
  officialRouteFromDb: any[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'name',
    page: 1,
    rowsPerPage: 10,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false
  itemsCurrency: string[] = []
  itemsRute: string[] = []
  newRutes: any = []
  available_shipment_methods: any = []
  searchFilterParamsCountryKeyword: string = ''
  expanded: any = []

  shipmentOld: any = []
  shipmentDel: any = []
  filter: any = {
    origin: [
      { text: 'Thailand', value: 208 },
      { text: 'Japan', value: 106 },
      { text: 'Singapore', value: 187 },
      { text: 'Malaysia', value: 128 },
      { text: 'Philippines', value: 167 },
      { text: 'USA', value: 222 },
      { text: 'China', value: 44 },
      { text: 'Indonesia', value: 99 }
    ],
    from: {
      selected: null,
      keyword: null
    },
    label: 'Filter Country From...',
    hint: 'Apply Country Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  //search origin & destination
  searchLoadingOri: boolean = false
  searchLoadingDes: boolean = false
  searchInputOri: string = ''
  searchInputDes: string = ''
  searchLabelOri: string = 'Search By Origin Name...'
  searchLabelDes: string = 'Search By Destination Name...'
  searchHint: string = 'input minimum 4 letters to search'
  searchIcon: string = 'search'
  idSearch: String = ''

  //add new courier
  inputInternationalCourierNew: string = null
  inputCourierItem: boolean = false

  itemsShipmentMethod: any = [
    {
      text: 'Air Courier',
      value: 'air_courier'
    },
    {
      text: 'Air Cargo',
      value: 'air'
    },
    {
      text: 'Air Door to Door',
      value: 'air_dtd'
    },
    {
      text: 'Ocean Cargo',
      value: 'ocean'
    },
    {
      text: 'Ocean Door to Door',
      value: 'ocean_dtd'
    },
    {
      text: 'Trucking Official',
      value: 'official_trucking'
    },
    {
      text: 'Trucking Un-Official',
      value: 'unofficial_trucking'
    }
  ]

  categories: any = {
    items: [],
    selected: [],
    keyword: null,
    loading: false
  }
  searchDebounce: Function
  currentCategories: any[] = []

  //table international shipping
  tableHeadersInternational: object[] = [
    {
      text: 'Courier Name',
      align: 'center',
      sortable: false,
      value: 'couriername'
    },
    {
      text: 'Rate Name',
      align: 'center',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Origin Country',
      align: 'center',
      sortable: false,
      value: 'origincountry'
    },
    {
      text: 'Origin Name',
      align: 'center',
      sortable: true,
      value: 'originname'
    },
    {
      text: 'Destination Country',
      align: 'center',
      sortable: false,
      value: 'destinationcountry'
    },
    {
      text: 'Destination Name',
      align: 'center',
      sortable: true,
      value: 'destinationname'
    },
    {
      text: 'Remark',
      align: 'center',
      sortable: true,
      value: 'remark'
    },
    // {
    //   text: 'Rate',
    //   align: 'center',
    //   sortable: true,
    //   value: 'rate'
    // },
    {
      text: 'Rate Details',
      align: 'center',
      sortable: false,
      value: 'ratedetails'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableLoadingInternational: boolean = false
  tableTotalItemsInternational: string | number = 0
  tableRowsPerPageItemsInternational: number[] = [5, 10, 15, 25, 50, 100]
  tablePaginationInternational: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 10,
    descending: true,
    totalItems: 0
  }

  pagination: any = {
    sortBy: 'id'
  }
  modelImportData: any = []
  modalBulkDelete: boolean = false
  checkboxAllSelected: boolean = false
  dataDomesticAllSelected: any = {}
  selectedId: any = []
  modalImportData: boolean = false

  //table rate details
  tableDetails: object[] = [
    {
      text: 'Weight',
      align: 'center',
      sortable: true,
      value: 'rate_per_weight'
    },
    {
      text: 'Rate',
      align: 'center',
      sortable: true,
      value: 'rate'
    }
  ]
  detailsRateDialog: boolean = false
  rateDetailsObject: any = {}
  tableItemsDetails: any = []
  tableItemsUpdate: any = []
  tableLoadingDetails: boolean = false
  tableTotalItemsDetails: string | number = 0
  tableRowsPerPageItemsDetails: number[] = [5, 10, 15, 25, 50, 100]
  tablePaginationDetails: any = {
    sortBy: 'rate_per_weight',
    page: 1,
    rowsPerPage: 10,
    descending: true,
    totalItems: 0
  }

  //open create & edit international shipping
  itemsInternational: any = []
  titleDialog: string = ''
  internationalDialog: boolean = false
  useAsEdit: boolean = false
  internationalDataDialog: any = {}
  itemShipMethod: any = ['Courier', 'Cargo']
  itemsUnit: any = ['Kg', 'Gr']
  itemsCalculation: any = ['FLAT', 'CUSTOM']
  itemOriginMethod: any = ['Cities', 'Provinces']
  itemDestinationMethod: any = ['Cities', 'Country', 'Provinces']
  itemImportOrigin: any = ['City', 'Province']
  itemImportDestination: any = ['City', 'Province']
  selectedEditId: string | number = null
  importDataDialog: any = {}
  
  //delete international shipping
  showDeleteModal: boolean = false
  selectedInternational: any = null

  //courier
  getCourierLoading: boolean = false
  courierKeywords: any = null
  searchCourierDebounce: Function
  itemCourier: any = []

  //countries
  getCountriesLoading: any = null
  itemOriginCountry: any = []
  itemDestinationCountry: any = []

  //currency international shipping
  itemCurrencies: any = []
  getCurrencyLoading: any = null
  currenciesKeywords: any = null
  searchCurrenciesDebounce: Function

  //getOne
  getDestCountryLoading: boolean = false
  getOneCityLoading: boolean = false
  getOneProvinceLoading: boolean = false

  //get Origin City & Province
  getCityLoading: boolean = false
  getProvinceLoading: boolean = false
  itemOriginName: any = []
  itemOriginKeywords: any = null

  //get Destination City & Province
  itemDestinationName:any = []
  itemDestinationKeywords: any = null
  searchOriginDebounce: Function
  searchDestinationDebounce: Function

  //rates
  dataRates: any = []
  propsDetail: any = {}

  async mounted() {
    this.getMembership()
    this.getOriginCountries()
    this.getDestinationCountries()
    this.getAllCurrency()
    this.filter.from.selected = { text: 'China', value: 44 }
    this.searchDebounce = await debounce(this.getCategories, 500)
    this.searchCurrenciesDebounce = debounce(this.getAllCurrency, 500)
    this.searchCourierDebounce = debounce(this.getCourier, 500)
    this.searchOriginDebounce = debounce(this.getOriginName, 500)
    this.searchDestinationDebounce = debounce(this.getDestinationName, 500)
  }

  @Watch('itemOriginKeywords')
  @Watch('itemDestinationKeywords')
  onitemNameChange() {
    this.searchOriginDebounce()
    this.searchDestinationDebounce()
  }

  @Watch('currencyKeyword')
  onCurrencyChanged() {
    // console.log('aa')
    this.currencyKeyword
    this.getCurrency()
  }

  @Watch('tablePagination', { deep: true })
  @Watch('searchInput', { deep: true })
  onChanged() {
    this.$vuetify.goTo(0)
    this.getCountry()
  }
  @Watch('tablePaginationInternational', { deep: true})
  onChangeInternational(){
    this.getInternationalShipping()
  }
  
  @Watch('currenciesKeywords')
  onCurrenciesChanged() {
    this.searchCurrenciesDebounce()
  }
  @Watch('courierKeywords')
  onCourierChange() {
    // this.searchCourierDebounce()
    if (this.courierKeywords != this.internationalDataDialog.courier_id && this.courierKeywords != 'Others...') {
      this.getCourier(null)
    }
  }
  @Watch('categories.keyword')
  async onChangedCategories() {
    await this.searchDebounce()
  }
  // @Watch('searchInputOri')
  // onOriginChanged() {
  //   if ((!isEmpty(this.searchInputOri) && this.searchInputOri.length > 3) || isEmpty(this.searchInputOri)) {
  //     this.getInternationalShipping()
  //   }
  // }
  // @Watch('searchInputDes')
  // onDestinationChanged() {
  //   if ((!isEmpty(this.searchInputDes) && this.searchInputDes.length > 3) || isEmpty(this.searchInputDes)) {
  //     this.getInternationalShipping()
  //   }
  // }

  @Watch('tablePaginationDetails', { deep: true })
  onDetails(){
    this.getdetails()
  }

  currencyKeyword: string = ''
  getCurrenciesLoading: boolean = false
  expandSelectCountryCurrency: string = ''
  expandSelectCountryId: string = null
  expandSelectIndex: number
  async onFilterChange() {
    this.closeExpand()
    this.filter.origin
    await this.getCountry()
  }

  closeExpand() {
    forEach((this.$refs as any).tableLogistic.expanded, (item, key) => {
      (this.$refs as any).tableLogistic.expanded[key] = false
    })
  }

  async refreshSearch() {
    await this.getInternationalShipping()
  }

  async onClearSearchDes() {
    this.searchInputDes = '',
    await this.refreshSearch()
  }

  async onClearSearchOri() {
    this.searchInputOri = '',
    await this.refreshSearch()
  }

  async theTableExpand(params, index) {
    this.expandSelectCountryCurrency = params.nameCountryCurrency
    this.expandSelectCountryId = params.id
    this.expandSelectIndex = index
    // this.currencyKeyword = params
    await this.getCurrency()
    this.currentCategories = []
    await this.getCategories(params.data.exclude_deminimis_category)
    if (!isEmpty(params.data.exclude_deminimis_category)) {
      this.currentCategories = this.categories.items
      this.tableItems[index].data.exclude_deminimis_category = this.currentCategories
    }
    this.idSearch = params.id
    await this.getInternationalShipping()
    this.getCourier(params.id)
    this.dataWholesale = {
      air_cargo : params.data.relation_weight_cost,
      ocean_cargo : params.data.relation_cbm_cost,
      air_dtd : params.data.relation_un_weight,
      ocean_dtd : params.data.relation_un_cbm,
      trucking_cargo: params.data.relation_trucking_cost,
      trucking_dtd: params.data.relation_un_trucking
    }
    if(!isEmpty(this.dataWholesale.air_cargo)){
      this.wholesale.air_cargo = true
    } else {
      this.wholesale.air_cargo = false
    }
    if(!isEmpty(this.dataWholesale.ocean_cargo)){
      this.wholesale.ocean_cargo = true
    } else {
      this.wholesale.ocean_cargo = false
    }
    if(!isEmpty(this.dataWholesale.air_dtd)){
      this.wholesale.air_dtd = true
    } else {
      this.wholesale.air_dtd = false
    }
    if(!isEmpty(this.dataWholesale.ocean_dtd)){
      this.wholesale.ocean_dtd = true
    } else {
      this.wholesale.ocean_dtd = false
    }
    if(!isEmpty(this.dataWholesale.trucking_cargo)){
      this.wholesale.trucking_cargo = true
    } else {
      this.wholesale.trucking_cargo = false
    }
    if(!isEmpty(this.dataWholesale.trucking_dtd)){
      this.wholesale.trucking_dtd = true
    } else {
      this.wholesale.trucking_dtd = false
    }

    if(params.data.status_promo_air_by_role == 'inactive'){
      this.enable_rate.air = false
    } else{
      this.enable_rate.air = true
    }

    if(params.data.status_promo_ocean_by_role == 'inactive'){
      this.enable_rate.ocean = false
    } else{
      this.enable_rate.ocean = true
    }

    if(params.data.status_promo_air_dtd_by_role == 'inactive'){
      this.enable_rate.air_dtd = false
    } else{
      this.enable_rate.air_dtd = true
    }

    if(params.data.status_promo_ocean_dtd_by_role == 'inactive'){
      this.enable_rate.ocean_dtd = false
    } else{
      this.enable_rate.ocean_dtd = true
    }

    if(params.data.status_promo_official_trucking_by_role == 'inactive'){
      this.enable_rate.official_trucking = false
    } else{
      this.enable_rate.official_trucking = true
    }

    if(params.data.status_promo_unofficial_trucking_by_role == 'inactive'){
      this.enable_rate.unofficial_trucking = false
    } else{
      this.enable_rate.unofficial_trucking = true
    }
  }
  
  onResetEnable(item, index, key, type){
    if(this.enable_rate[type] == false) {
      this.disableRole(item,index,key)
      // console.log(item, 'item')
    } else if(this.enable_rate[type] == true){
      this.enableRole(item)
    }
  }
  onSelectCategory(categoriesSelected) {
    this.currentCategories = categoriesSelected
  }

  async getCategories(id = [], index = 0) {
    // console.log('id',id)
    // var lengthLimit = id.length
    // if (lengthLimit === 0) {
    //   lengthLimit = 20
    // }
    this.categories.loading = true
    try {
      const opts: any = {
        // set to dynamic
        params: {
          // 'page[limit]': 10,
          // 'page[limit]': lengthLimit,
          'page[num]': 1,
          'page[limit]': 10, // Calling all country. Reducing constant filter request to the server
          'filter[description][like]': '',
          'filter[id][in]': !isEmpty(id) ? id.toString() : null
        }
      }
      if (!isEmpty(this.categories.keyword)) {
        var textFilter = 'filter[description][like]'
        opts.params[textFilter] = this.categories.keyword
      } else {
        var textFilter = 'filter[description][like]'
        delete opts.params[textFilter]
      }
      const response = await RulesService.getCategoryRules(opts)
      const responseData = response.data.data
      this.categories.items = []
      if (!isEmpty(this.currentCategories)) {
        for (const categoriesSelected of this.currentCategories) {
          this.categories.items.push(categoriesSelected)
        }
      }
      for (const dataCategories of responseData) {
        this.categories.items.push(dataCategories.attributes)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.categories.loading = false
    }
  }

  async getMembership() {
    try{
      this.member.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 25
        }
      }
      const response = await UserSettings.getMemberships(opts)
      this.member.items = []
      // console.log(response)
      forEach(response.data.data, membership => {
        const item = {
          id: membership.attributes.id,
          name: membership.attributes.name
        }
        this.member.items.push(item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.member.loading = false
    }
  }

  async getCurrency() {
    try {
      this.getCurrenciesLoading = true
      const optsCurrency: any = {
        params: {}
      }
      if (!isEmpty(this.currencyKeyword)) {
        // console.log('++')
        optsCurrency.params = {
          ...optsCurrency.params,
          'filter[currency_code_target][like]': this.currencyKeyword
        }
      } else {
        // console.log('--')
        optsCurrency.params = {
          ...optsCurrency.params,
          // cahnge this value by default from 
          'filter[currency_code_target][like]': this.expandSelectCountryCurrency
        }
      }
      const response = await CurrencyService.getCurrencies(optsCurrency)
      const responseData = response.data.data
      this.itemsCurrency = []
      forEach(responseData, dataCurrency => {
        this.itemsCurrency.push(dataCurrency.attributes.currency_code_target)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCurrenciesLoading = false
    }
  }

  async getAllCurrency(){
    this.getCurrenciesLoading = true
    try {
      const opts: any = {
        params: {
          'filter[currency][null]': false,
          'page[num]': 1,
          'page[limit]': 10, // Calling all country. Reducing constant filter request to the server
          'filter[currency][like]': ''
        }
      }
      if (!isEmpty(this.currenciesKeywords)) {
        var textFilter = 'filter[currency][like]'
        opts.params[textFilter] = this.currenciesKeywords
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[currency][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemCurrencies = []
      for (const country of responseData) {
        this.itemCurrencies.push(country.attributes.currency)
      }
      this.itemCurrencies = sortBy(this.itemCurrencies, 'currency')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCurrenciesLoading = false
    }
  }

  async getRute() {
    try {
      const response: any = await SettingsService.getSettings()
      const responseDataRute = response.data
      this.itemsRute = []
      this.itemsRute = filter(responseDataRute, routes => routes.key === 'official_routes')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async updateRoutes(routes) {
    try {
      this.showLoading({ text: 'Saving Data...' })

      var payload_create_official_routes = {
        data: []
      }
      var payload_edit_official_routes = {
        data: []
      }
      var payload_shipment_delete = {
        data: {
          data: [
          ]
        }
      }
      forEach(routes, route => {
        // create
        if (route.id === null) {
          var set_item_create_route = {
            attributes: {
              key: route.attributes.key,
              country_id: route.attributes.country_id,
              source_country_id: route.attributes.source_country_id,
              value: route.attributes.value
            }
          }
          payload_create_official_routes.data.push(set_item_create_route)
        } else {
          // edit
          var set_item_update_route = {
            id: route.attributes.id,
            attributes: {
              key: route.attributes.key,
              country_id: route.attributes.country_id,
              source_country_id: route.attributes.source_country_id,
              value: route.attributes.value
            }
          }
          payload_edit_official_routes.data.push(set_item_update_route)
          // console.log('edit')
        }
      })

      if (payload_create_official_routes.data.length > 0) {
        // console.log('payload_create_official_routes',payload_create_official_routes)
        const responseCreate = await SettingsService.createSettings(payload_create_official_routes)
      }

      if (payload_edit_official_routes.data.length > 0) {
        // console.log('payload_edit_official_routes',payload_edit_official_routes)
        const responseEdit = await SettingsService.updateSettings(payload_edit_official_routes)
      }

      if (this.id_routes_deleted.length > 0) {
        // console.log('payload_delete_official_routes',this.id_routes_deleted)
        forEach(this.id_routes_deleted, item => {
          payload_shipment_delete.data.data.push(item)
          // console.log('item',item)
        })
        // console.log('payload_shipment_delete',payload_shipment_delete)
        const responseDelete = await SettingsService.deleteSettings(payload_shipment_delete)
      }
      this.closeExpand()
      this.showSnackbar({ text: 'Saved Successfully!', color: 'green', timeout: 2500 })
    } catch (error) {
      this.showSnackbar(error)
    } finally {
      this.closeLoading()
      this.getCountry()
      // reset delete route
      this.id_routes_deleted = []
    }
  }

  onAddNewDeminimis() {
    // console.log('this.tableItems',this.tableItems)

    const newLevelDeminimis = {
      value: 0,
      duty: 0,
      wht: 0,
      vat: 0
    }
    this.tableItems[this.expandSelectIndex].data.deminimis_limit.push(newLevelDeminimis)
  }

  onDeminimisDeleted(index) {
    // console.log('index',index)
    // console.log('this.expandSelectIndex',this.expandSelectIndex)
    // console.log('this.tableItems[this.expandSelectIndex].data', this.tableItems[this.expandSelectIndex].data)
    this.tableItems[this.expandSelectIndex].data.deminimis_limit.splice(index, 1)
  }

  onAddNewRute() {
    // console.log('this.tableItems[0].data',this.tableItems[0].data)
    // console.log('this.expandSelectCountryId',this.expandSelectCountryId)
    // console.log('this.global_var_country_id',this.global_var_country_id)

    const newRoute = {
      id: null,
      type: 'global_settings',
      attributes: {
        country_id: Number(this.global_var_country_id),
        id: null,
        key: 'official_routes',
        role_ids: null,
        source_country_id: this.filter.from.selected.value,
        value: null
      }
    }
    if (isNaN(newRoute.attributes.country_id)) {
      // console.log('this.expandSelectIndex',this.expandSelectIndex)
      newRoute.attributes.country_id = Number(this.expandSelectCountryId)
    }
    // console.log('newRoute',newRoute)
    // old
    // this.tableItems[0].data.official_routes.push(newRoute)
    this.tableItems[this.expandSelectIndex].data.official_routes.push(newRoute)
  }

  onAddNewRate() {
    const rates = {
      id: null,
      rate : '',
      rate_per_weight : '' 
    }
    this.dataRates.push(rates)
  }

  onWholesale(index_data) {
    const wholesale = {
      id: null,
      min_quantity: '',
      price: ''
    }
    this.dataWholesale[index_data].push(wholesale)
  }

  changeWholesale(index_data) {
    if(this.wholesale[index_data] == true){
      this.onWholesale(index_data)
    } else {
      this.tempWholesale[index_data] = this.dataWholesale[index_data]
      this.dataWholesale[index_data] = []
    }
  }

  // changeInsurance(){
  //   this.insurance = !this.insurance
  // }

  async onWholesaleDeleted(index_data,index) {
    this.dataWholesale[index_data].splice(index, 1)
  }

  async wholesalePrice(props){
    this.wholesale_id_cbm = props.data.id_official_cbm_cost
    this.wholesale_id_weight = props.data.id_official_weight_cost
    this.wholesale_id_cbmdtd = props.data.id_unofficial_cbm_cost
    this.wholesale_id_weightdtd = props.data.id_unofficial_weight_cost
    this.wholesale_id_trucking = props.data.id_official_trucking_cost
    this.wholesale_id_truckingdtd = props.data.id_unofficial_trucking_cost

    try{
      let payload_wholesale_air_cargo = {
        type: 'GlobalSetting',
        data: []
      }
      let payload_wholesale_ocean_cargo = {
        type: 'GlobalSetting',
        data: []
      }
      let payload_wholesale_air_dtd = {
        type: 'GlobalSetting',
        data: []
      }
      let payload_wholesale_ocean_dtd = {
        type: 'GlobalSetting',
        data: []
      }
      let payload_wholesale_trucking = {
        type: 'GlobalSetting',
        data: []
      }
      let payload_wholesale_trucking_dtd = {
        type: 'GlobalSetting',
        data: []
      }
      
      // if(isEmpty(this.dataWholesale.air_cargo)){
      if(this.wholesale.air_cargo === false){
        this.wholesaleDelete(props)
      } else {
        //air_cargo
        for (let wholesale of this.dataWholesale.air_cargo){
          if(wholesale.id === null){
            payload_wholesale_air_cargo.data.push(
              {
                min_quantity: wholesale.min_quantity,
                price: wholesale.price
              }
            )
          } else {
            payload_wholesale_air_cargo.data.push(
              {
                id: wholesale.id,
                attributes: {
                  min_quantity: wholesale.min_quantity,
                  price: wholesale.price
                }
              }
            )
          }
        }
        await WholesaleService.wholesale(this.wholesale_id_weight, payload_wholesale_air_cargo)
      }
      // }
     
      if(this.wholesale.ocean_cargo === false){
        this.wholesaleDelete(props)
      } else {
        //ocean_cargo
        for (let wholesale of this.dataWholesale.ocean_cargo){
          if(wholesale.id === null){
            payload_wholesale_ocean_cargo.data.push(
              {
                min_quantity: wholesale.min_quantity,
                price: wholesale.price
              }
            )
          } else {
            payload_wholesale_ocean_cargo.data.push(
              {
                id: wholesale.id,
                attributes: {
                  min_quantity: wholesale.min_quantity,
                  price: wholesale.price
                }
              }
            )
          }
        }
        await WholesaleService.wholesale(this.wholesale_id_cbm, payload_wholesale_ocean_cargo)
      }
      
      if(this.wholesale.air_dtd === false){
        this.wholesaleDelete(props)
      } else {
        //air_dtd
        for (let wholesale of this.dataWholesale.air_dtd){
          if(wholesale.id === null){
            payload_wholesale_air_dtd.data.push(
              {
                min_quantity: wholesale.min_quantity,
                price: wholesale.price
              }
            )
          } else {
            payload_wholesale_air_dtd.data.push(
              {
                id: wholesale.id,
                attributes: {
                  min_quantity: wholesale.min_quantity,
                  price: wholesale.price
                }
              }
            )
          }
        }
        await WholesaleService.wholesale(this.wholesale_id_weightdtd, payload_wholesale_air_dtd)
      }
      
      if(this.wholesale.ocean_dtd === false){
        this.wholesaleDelete(props)
      } else {
        //ocean_dtd
        for (let wholesale of this.dataWholesale.ocean_dtd){
          if(wholesale.id === null){
            payload_wholesale_ocean_dtd.data.push(
              {
                min_quantity: wholesale.min_quantity,
                price: wholesale.price
              }
            )
          } else {
            payload_wholesale_ocean_dtd.data.push(
              {
                id: wholesale.id,
                attributes: {
                  min_quantity: wholesale.min_quantity,
                  price: wholesale.price
                }
              }
            )
          }
        }
        await WholesaleService.wholesale(this.wholesale_id_cbmdtd, payload_wholesale_ocean_dtd)
      }

      if(this.wholesale.trucking_cargo === false){
        this.wholesaleDelete(props)
      } else {
        for (let wholesale of this.dataWholesale.trucking_cargo){
          if(wholesale.id === null){
            payload_wholesale_trucking.data.push(
              {
                min_quantity: wholesale.min_quantity,
                price: wholesale.price
              }
            )
          } else {
            payload_wholesale_trucking.data.push(
              {
                id: wholesale.id,
                attributes: {
                  min_quantity: wholesale.min_quantity,
                  price: wholesale.price
                }
              }
            )
          }
        }
        await WholesaleService.wholesale(this.wholesale_id_trucking, payload_wholesale_trucking)
      }

      if(this.wholesale.trucking_dtd === false){
        this.wholesaleDelete(props)
      } else {
        for (let wholesale of this.dataWholesale.trucking_dtd){
          if(wholesale.id === null){
            payload_wholesale_trucking_dtd.data.push(
              {
                min_quantity: wholesale.min_quantity,
                price: wholesale.price
              }
            )
          } else {
            payload_wholesale_trucking_dtd.data.push(
              {
                id: wholesale.id,
                attributes: {
                  min_quantity: wholesale.min_quantity,
                  price: wholesale.price
                }
              }
            )
          }
        }
        await WholesaleService.wholesale(this.wholesale_id_truckingdtd, payload_wholesale_trucking_dtd)
      }

      this.closeExpand()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async wholesaleDelete(props) {
    this.wholesale_id_cbm = props.data.id_official_cbm_cost
    this.wholesale_id_weight = props.data.id_official_weight_cost
    this.wholesale_id_cbmdtd = props.data.id_unofficial_cbm_cost
    this.wholesale_id_weightdtd = props.data.id_unofficial_weight_cost
    this.wholesale_id_trucking = props.data.id_official_trucking_cost
    this.wholesale_id_truckingdtd = props.data.id_unofficial_trucking_cost

    try {
      let data = {
        data: {
          type: 'GlobalSetting'
        }
      }
      this.showLoading({ text: 'Saving...' })
      for(let wholesale of this.tempWholesale.air_cargo){
        if(wholesale.id){
          await WholesaleService.deleteWholesale(this.wholesale_id_weight, data)
        } 
      }
      
      for(let wholesale of this.tempWholesale.ocean_cargo){
        if(wholesale.id){
          await WholesaleService.deleteWholesale(this.wholesale_id_cbm, data)
        }
      }
      for(let wholesale of this.tempWholesale.air_dtd){
        if(wholesale.id){
          await WholesaleService.deleteWholesale(this.wholesale_id_weightdtd, data)
        }
      }
      for(let wholesale of this.tempWholesale.ocean_dtd){
        if(wholesale.id){
          await WholesaleService.deleteWholesale(this.wholesale_id_cbmdtd, data)
        }
      }
      for(let wholesale of this.tempWholesale.trucking_cargo){
        if(wholesale.id){
          await WholesaleService.deleteWholesale(this.wholesale_id_trucking, data)
        }
      }
      for(let wholesale of this.tempWholesale.trucking_dtd){
        if(wholesale.id){
          await WholesaleService.deleteWholesale(this.wholesale_id_truckingdtd, data)
        }
      }
      this.closeExpand()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async onRatesDeleted(index,id) {
    if(id !== null){
      const response = await DomesticService.deleteDomestic(id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
    }
    this.dataRates.splice(index, 1)
  }

  onRuteDeleted(newRutesIndex, id = null) {
    // console.log('newRutesIndex',newRutesIndex)
    // console.log('id',id)
    // this.newRutes.splice(newRutesIndex, 1)
    if (id !== null) {
      const id_del = {
        'id': id
      }
      this.id_routes_deleted.push(id_del)
      // this.newRutes.splice(newRutesIndex, 1)
      this.tableItems[this.expandSelectIndex].data.official_routes.splice(newRutesIndex, 1)
    } else {
      // this.newRutes.splice(newRutesIndex, 1)
      this.tableItems[this.expandSelectIndex].data.official_routes.splice(newRutesIndex, 1)
    }
  }


  async getCourier(idCountry) {
    this.getCourierLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][in]': idCountry
        }
      }
      if (!isEmpty(this.courierKeywords)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.courierKeywords
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CourierService.getCouriers(opts)
      const responseData = response.data.data
      this.itemCourier = []
      if (response.data.success) {
        this.itemCourier.push({
          id: 'oth',
          name: 'Others...'
        })
      }
      for (const courier of responseData) {
        this.itemCourier.push({
          name: courier.attributes.name,
          id: courier.attributes.id
        })
      }
      this.itemCourier = sortBy(this.itemCourier, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCourierLoading = false
    }
  }

  async getInternationalShipping(){
    const opts: any = {
      params : {
        'page[num]': this.tablePaginationInternational.page,
        'page[limit]': this.tablePaginationInternational.rowsPerPage,
        'filter[origin_country_id][is]': this.filter.from.selected.value,
        'filter[destination_country_id][is]': this.idSearch,
        sort: this.tablePaginationInternational.descending ? '-' + this.tablePaginationInternational.sortBy : this.tablePaginationInternational.sortBy,
        // 'filter[shipping_types][is]': 'International',
        diverse: 'International',
        include: 'country,courier,destinationCountry'
      }
    }
    try {
      if (this.searchInputOri != null && this.searchInputOri != '') {
        const optsOri: any = {
          params: {
            'filter[name][like]': this.searchInputOri,
            'filter[country_id][is]': this.internationalDataDialog.origin_country_id
          }
        }
        const responseCityOri = await CityService.getCities(optsOri)
        var idCityOri = join(map(responseCityOri.data.data, 'id'), ',')
        const responseProvOri = await ProvinceService.getProvinces(optsOri)
        var idProvOri = join(map(responseProvOri.data.data, 'id'), ',')
        opts.params = {
          ...opts.params,
          'filter[origin_id][in]': join([idCityOri, idProvOri], ',')
        }
      } else {
        var textFilter = 'filter[origin_id][in]'
        delete opts.params[textFilter]
      }
      if (this.searchInputDes != null && this.searchInputDes != '') {
        const optsDes: any = {
          params: {
            'filter[name][like]': this.searchInputDes
            // 'filter[country_id][is]': this.internationalDataDialog.origin_country_id
          }
        }
        const responseCityDes = await CityService.getCities(optsDes)
        const idCityDes = join(map(responseCityDes.data.data, 'id'), ',')
        const responseProvDes = await ProvinceService.getProvinces(optsDes)
        const idProvDes = join(map(responseProvDes.data.data, 'id'), ',')
        const responseCountryDes = await CountryService.getCountries(optsDes)
        const idCountryDes = join(map(responseCountryDes.data.data, 'id'), ',')
        opts.params = {
          ...opts.params,
          'filter[destination_id][in]': join([idCityDes, idProvDes, idCountryDes], ',')
        }
      } else {
        var textFilter = 'filter[destination_id][in]'
        delete opts.params[textFilter]
      }
      this.itemsInternational = []
      const response: any = await DomesticService.getDomestic(opts)
      const responseDataShipping = response.data.data
      var arr = responseDataShipping
      var filtered = arr.filter(function (a) {
        var key = a.attributes.origin_id + '|' + a.attributes.destination_id
        if (!this[key]) {
          this[key] = true
          return true
        }
      }, Object.create(null))
      const responseDataInclude = response.data.included
      this.tableTotalItemsInternational = response.data.meta.pagination.total
      forEach(filtered, async (dataShipping) => {
        let idGroupShipping = []
        forEach(responseDataShipping, dashi => {
          if(dashi.attributes.origin_id == dataShipping.attributes.origin_id && dashi.attributes.destination_id == dataShipping.attributes.destination_id){
            idGroupShipping.push(dashi.attributes.id)
          }
        })
        const destCountry = await this.getDestCountry(dataShipping.attributes.destination_country_id)
        var origin_name = ''
        var destination_name = ''
        if (lowerCase(dataShipping.attributes.origin_type) == 'cities') {
          origin_name = await this.getOneCities(dataShipping.attributes.origin_id)
        } else if (lowerCase(dataShipping.attributes.origin_type) == 'provinces') {
          origin_name = await this.getOneProvinces(dataShipping.attributes.origin_id)
        }
        if (lowerCase(dataShipping.attributes.destination_type) == 'country') {
          destination_name = await this.getDestCountry(dataShipping.attributes.destination_id)
        } else if (lowerCase(dataShipping.attributes.destination_type) == 'cities') {
          destination_name = await this.getOneCities(dataShipping.attributes.destination_id)
        } else if (lowerCase(dataShipping.attributes.destination_type) == 'provinces') {
          destination_name = await this.getOneProvinces(dataShipping.attributes.destination_id)
        }
        const allShipping = {
          ...dataShipping.attributes,
          courierName: responseDataInclude.courier[dataShipping.attributes.courier_id].attributes.name,
          countryName: responseDataInclude.country[dataShipping.attributes.origin_country_id].attributes.name,
          destinationCountry: destCountry,
          originName: origin_name,
          destinationName: destination_name,
          idGroup: idGroupShipping
        }
        const findIndexCountry = findIndex(this.itemsInternational, (table:any) => table.id === allShipping.id)
        if (findIndexCountry === -1 || findIndexCountry === undefined) {
          this.itemsInternational.push(allShipping)
        } else {
          this.itemsInternational[findIndexCountry] = allShipping
        }
        this.checkboxAllSelected = false
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getDestCountry(id) {
    this.getDestCountryLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getOneCountry(id, opts)
      const responseData = response.data.data
      const destCountry = responseData.attributes.name
      return destCountry
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getDestCountryLoading = false
    }
  }

  async getOneCities(id) {
    this.getOneCityLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CityService.getOneCities(id, opts)
      const responseData = response.data.data
      const citiesName = responseData.attributes.name
      return citiesName
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getOneCityLoading = false
    }
  }

  async getOneProvinces(id) {
    this.getOneProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await ProvinceService.getOneProvinces(id, opts)
      const responseData = response.data.data
      const provinceName = responseData.attributes.name
      return provinceName
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getOneProvinceLoading = false
    }
  }

  async getDestinationCities() {
    this.getCityLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.internationalDataDialog.destination_country_id
        }
      }
      if (!isEmpty(this.itemDestinationKeywords)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.itemDestinationKeywords
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CityService.getCities(opts)
      const responseData = response.data.data
      this.itemDestinationName = []
      for (const city of responseData) {
        // this.itemDestinationName.push(city.attributes)
        this.itemDestinationName.push({
          name: city.attributes.name,
          id: city.attributes.id
        })
      }
      this.itemDestinationName = sortBy(this.itemDestinationName, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCityLoading = false
    }
  }

  async getDestinationProvinces() {
    this.getProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.internationalDataDialog.destination_country_id
        }
      }
      if (!isEmpty(this.itemDestinationKeywords)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.itemDestinationKeywords
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await ProvinceService.getProvinces(opts)
      const responseData = response.data.data
      this.itemDestinationName = []
      for (const province of responseData) {
        // this.itemDestinationName.push(province.attributes)
        this.itemDestinationName.push({
          name: province.attributes.name,
          id: province.attributes.id
        })
      }
      this.itemDestinationName = sortBy(this.itemDestinationName, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getProvinceLoading = false
    }
  }

  getOriginName() {
    if (lowerCase(this.internationalDataDialog.origin_type) == 'cities') {
      this.getOriginCities()
    } else if (lowerCase(this.internationalDataDialog.origin_type) == 'provinces') {
      this.getOriginProvinces()
    }
  }

  getDestinationName() {
    if (lowerCase(this.internationalDataDialog.destination_type) == 'country') {
      this.getDestinationCountry()
    } else if (lowerCase(this.internationalDataDialog.destination_type) == 'cities') {
      this.getDestinationCities()
    } else if (lowerCase(this.internationalDataDialog.destination_type) == 'provinces') {
      this.getDestinationProvinces()
    }
  }

  async getdetails(){
    const opts: any = {
      params: {
        'page[num]': this.tablePaginationDetails.page,
        'page[limit]': this.tablePaginationDetails.rowsPerPage,
        diverse: 'International',
        'filter[origin_country_id][is]' : this.propsDetail.item.origin_country_id,
        'filter[destination_country_id][is]' : this.propsDetail.item.destination_country_id,
        'filter[origin_id][is]' : this.propsDetail.item.origin_id,
        'filter[destination_id][is]' : this.propsDetail.item.destination_id,
        sort: this.tablePaginationDetails.descending ? '-' + this.tablePaginationDetails.sortBy : this.tablePaginationDetails.sortBy
      }
    }
    try {
      this.tableLoadingDetails = true
      const response: any = await DomesticService.getDomestic(opts)
      const responseData = response.data.data
      this.tableTotalItemsDetails = response.data.meta.pagination.total
      this.tableItemsDetails = []
      for (let dataDetails of responseData){
        const rates = {
          currency : dataDetails.attributes.currency,
          weight_unit : dataDetails.attributes.weight_unit,
          rate : dataDetails.attributes.rate,
          rate_per_weight : dataDetails.attributes.rate_per_weight
        } 
        this.tableItemsDetails.push(rates)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoadingDetails = false
    }
  } 

  openRateDetails(props) {
    this.propsDetail = props
    this.getdetails()
    this.rateDetailsObject = {
      calculation: upperCase(props.item.calculation),
      minimum: props.item.minimum,
      maximum: props.item.maximum,
      remarks: props.item.remarks,
      rate_per_weight: props.item.rate_per_weight
    }
    this.detailsRateDialog = true
  }

  async getCountry() {
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        'sort': this.tablePagination.sortBy,
        'filter[name][like]': this.searchFilterParamsCountryKeyword
      }
    }
    try {
      this.tableLoading = true
      const response: any = await CountryService.getCountries(opts)
      this.global_var_country_id = join(map(response.data.data, 'id'), ',')
      if (response.data.success) {
        this.tableTotalItems = response.data.meta.pagination.total
        const optsSettings: any = {
          params: {
            'filter[country_id][in]': join(map(response.data.data, 'id'), ','),
            // 'filter[source_country_id][is]': this.filter.from.selected
            'filter[source_country_id][in]': `${this.filter.from.selected.value},null`,
            // value for the limit of global setting first reload
            'page[limit]': 100,
            include: 'wholesale_prices'
          }
        }
        const responseSettings = await SettingsService.getSettings(optsSettings)
        const responseDataSettings = responseSettings.data.data
        const responseIncludeSettings = responseSettings.data.included
        this.tableItems = []
        for (let dataCountry of response.data.data) {
          const data: any = filter(responseDataSettings, setting => setting.attributes.country_id === dataCountry.attributes.id)
          const official_weight_cost = find(data, setting => setting.attributes.key === 'official_weight_cost')
          const official_weight_currency = find(data, setting => setting.attributes.key === 'official_weight_currency')
          const official_cbm_cost = find(data, setting => setting.attributes.key === 'official_cbm_cost')
          const official_cbm_currency = find(data, setting => setting.attributes.key === 'official_cbm_currency')
          const official_vat = find(data, setting => setting.attributes.key === 'official_vat')
          const official_routes = filter(data, setting => setting.attributes.key === 'official_routes')
          this.officialRouteFromDb = filter(data, setting => setting.attributes.key === 'official_routes')
          const unofficial_weight_cost = find(data, setting => setting.attributes.key === 'unofficial_weight_cost')
          const unofficial_weight_currency = find(data, setting => setting.attributes.key === 'unofficial_weight_currency')
          const unofficial_cbm_cost = find(data, setting => setting.attributes.key === 'unofficial_cbm_cost')
          const unofficial_cbm_currency = find(data, setting => setting.attributes.key === 'unofficial_cbm_currency')
          const available_shipment_methods = filter(data, setting => setting.attributes.key === 'available_shipment_methods')
          const default_shipment_method = find(data, setting => setting.attributes.key === 'default_shipment_method')
          const exclude_deminimis_category = find(data, setting => setting.attributes.key === 'exclude_deminimis_category' && setting.attributes.source_country_id === null)
          // console.log('exclude_deminimis_category',exclude_deminimis_category)
          const deminimis_currency = find(data, setting => setting.attributes.key === 'deminimis_currency')
          const deminimis_limit = find(data, setting => setting.attributes.key === 'deminimis_limit')
          if (typeof deminimis_limit !== 'undefined') {
            deminimis_limit.attributes.value = JSON.parse(deminimis_limit.attributes.value)
          }
          const shipping_term = find(data, setting => setting.attributes.key === 'shipping_term')
          const handling_charges = find(data, setting => setting.attributes.key === 'handling_charges')
          this.shipmentOld = available_shipment_methods
          var air = false
            , ocean = false
            , air_dtd = false
            , ocean_dtd = false
            , air_courier = false
            , official_trucking = false
            , unofficial_trucking = false
          for (let shipmentMethod of available_shipment_methods) {
            if (shipmentMethod.attributes.value == 'air') {
              air = true
            } else if (shipmentMethod.attributes.value == 'ocean') {
              ocean = true
            } else if (shipmentMethod.attributes.value == 'air_dtd') {
              air_dtd = true
            } else if (shipmentMethod.attributes.value == 'ocean_dtd') {
              ocean_dtd = true
            } else if (shipmentMethod.attributes.value == 'air_courier') {
              air_courier = true
            } else if (shipmentMethod.attributes.value == 'official_trucking') {
              official_trucking = true
            } else if (shipmentMethod.attributes.value == 'unofficial_trucking') {
              unofficial_trucking = true
            }
          }
          const official_weight_step  = find(data, setting => setting.attributes.key === 'official_weight_step')
          const official_min_weight = find(data, setting => setting.attributes.key === 'official_min_weight')
          const official_cbm_step = find(data, setting => setting.attributes.key === 'official_cbm_step')
          const official_min_cbm = find(data, setting => setting.attributes.key === 'official_min_cbm')
          const unofficial_weight_step = find(data, setting => setting.attributes.key === 'unofficial_weight_step')
          const unofficial_min_weight = find(data, setting => setting.attributes.key === 'unofficial_min_weight')
          const unofficial_cbm_step = find(data, setting => setting.attributes.key === 'unofficial_cbm_step') 
          const unofficial_min_cbm = find(data, setting => setting.attributes.key === 'unofficial_min_cbm')
          const official_trucking_cost = find(data, setting => setting.attributes.key === 'official_trucking_cost')
          const official_trucking_currency = find(data, setting => setting.attributes.key === 'official_trucking_currency')
          const official_trucking_step = find(data, setting => setting.attributes.key === 'official_trucking_step')
          const official_min_trucking = find(data, setting => setting.attributes.key === 'official_min_trucking')
          const unofficial_trucking_cost = find(data, setting => setting.attributes.key === 'unofficial_trucking_cost')
          const unofficial_trucking_currency = find(data, setting => setting.attributes.key === 'unofficial_trucking_currency')
          const unofficial_trucking_step = find(data, setting => setting.attributes.key === 'unofficial_trucking_step')
          const unofficial_min_trucking = find(data, setting => setting.attributes.key === 'unofficial_min_trucking')
          const status_promo_air_by_role = find(data, setting => setting.attributes.key === 'promo_air_by_role')
          const status_promo_ocean_by_role = find(data, setting => setting.attributes.key === 'promo_ocean_by_role')
          const status_promo_official_trucking_by_role = find(data, setting => setting.attributes.key === 'promo_official_trucking_by_role')
          const status_promo_air_dtd_by_role = find(data, setting => setting.attributes.key === 'promo_air_dtd_by_role')
          const status_promo_ocean_dtd_by_role = find(data, setting => setting.attributes.key === 'promo_ocean_dtd_by_role')
          const status_promo_unofficial_trucking_by_role = find(data, setting => setting.attributes.key === 'promo_unofficial_trucking_by_role')
          const relation_weight_cost = isEmpty(official_weight_cost) ? 0 : official_weight_cost.relationships.wholesale_prices
          const relation_cbm_cost = isEmpty(official_cbm_cost) ? 0 : official_cbm_cost.relationships.wholesale_prices
          const relation_un_weight = isEmpty(unofficial_weight_cost) ? 0 : unofficial_weight_cost.relationships.wholesale_prices
          const relation_un_cbm = isEmpty(unofficial_cbm_cost) ? 0 : unofficial_cbm_cost.relationships.wholesale_prices
          const relation_trucking_cost = isEmpty(official_trucking_cost) ? 0 : official_trucking_cost.relationships.wholesale_prices
          const relation_un_trucking = isEmpty(unofficial_trucking_cost) ? 0 : unofficial_trucking_cost.relationships.wholesale_prices
          var cbmcost = []
          var weightcost = []
          var uncbmcost = []
          var unweightcost = []
          var truckingcost = []
          var untruckingcost = []
          if(relation_cbm_cost !== 0){
            for (let relation of relation_cbm_cost){
              const include_cbm_cost = responseIncludeSettings.wholesale_prices[relation.id].attributes
              cbmcost.push(include_cbm_cost)
            }
          }
          if(relation_weight_cost !== 0){
            for (let relation of relation_weight_cost){
              const include_weight_cost = responseIncludeSettings.wholesale_prices[relation.id].attributes
              weightcost.push(include_weight_cost)
            }
          }
          if(relation_un_weight !== 0){
            for (let relation of relation_un_weight){
              const include_un_weight = responseIncludeSettings.wholesale_prices[relation.id].attributes
              unweightcost.push(include_un_weight)
            }
          }
          if(relation_un_cbm !== 0){
            for (let relation of relation_un_cbm){
              const include_un_cbm = responseIncludeSettings.wholesale_prices[relation.id].attributes
              uncbmcost.push(include_un_cbm)
            }
          }
          if(relation_trucking_cost !== 0){
            for (let relation of relation_trucking_cost){
              const include_trucking_cost = responseIncludeSettings.wholesale_prices[relation.id].attributes
              truckingcost.push(include_trucking_cost)
            }
          }
          if(relation_un_trucking !== 0){
            for (let relation of relation_un_trucking){
              const include_un_trucking = responseIncludeSettings.wholesale_prices[relation.id].attributes
              untruckingcost.push(include_un_trucking)
            }
          }
         
          const country = {
            id: dataCountry.attributes.id,
            nameCountry: dataCountry.attributes.name,
            nameCountryCurrency: isEmpty(official_weight_currency) ? null : official_weight_currency.attributes.value,
            expanded: false,
            data: {
              id_official_weight_cost: isEmpty(official_weight_cost) ? null : official_weight_cost.attributes.id,
              official_weight_cost: isEmpty(official_weight_cost) ? 0 : official_weight_cost.attributes.value,
              id_official_weight_currency: isEmpty(official_weight_currency) ? null : official_weight_currency.attributes.id,
              official_weight_currency: isEmpty(official_weight_currency) ? null : official_weight_currency.attributes.value,
              id_official_cbm_cost: isEmpty(official_cbm_cost) ? null : official_cbm_cost.attributes.id,
              official_cbm_cost: isEmpty(official_cbm_cost) ? 0 : official_cbm_cost.attributes.value,
              id_official_cbm_currency: isEmpty(official_cbm_currency) ? null : official_cbm_currency.attributes.id,
              official_cbm_currency: isEmpty(official_cbm_currency) ? null : official_cbm_currency.attributes.value,
              id_official_vat: isEmpty(official_vat) ? null : official_vat.attributes.id,
              official_vat: isEmpty(official_vat) ? 0 : official_vat.attributes.value, 
              
              id_unofficial_weight_cost: isEmpty(unofficial_weight_cost) ? null : unofficial_weight_cost.attributes.id,
              unofficial_weight_cost: isEmpty(unofficial_weight_cost) ? 0 : unofficial_weight_cost.attributes.value,
              id_unofficial_weight_currency: isEmpty(unofficial_weight_currency) ? null : unofficial_weight_currency.attributes.id,
              unofficial_weight_currency: isEmpty(unofficial_weight_currency) ? null : unofficial_weight_currency.attributes.value,
              id_unofficial_cbm_cost: isEmpty(unofficial_cbm_cost) ? null : unofficial_cbm_cost.attributes.id,
              unofficial_cbm_cost: isEmpty(unofficial_cbm_cost) ? 0 : unofficial_cbm_cost.attributes.value,
              id_unofficial_cbm_currency: isEmpty(unofficial_cbm_currency) ? null : unofficial_cbm_currency.attributes.id,
              unofficial_cbm_currency: isEmpty(unofficial_cbm_currency) ? null : unofficial_cbm_currency.attributes.value,
             
              available_shipment_methods: isEmpty(available_shipment_methods) ? [] : available_shipment_methods,
              id_deminimis_currency: isEmpty(deminimis_currency) ? null : deminimis_currency.attributes.id,
              deminimis_currency: isEmpty(deminimis_currency) ? null : deminimis_currency.attributes.value,
              id_deminimis_limit: isEmpty(deminimis_limit) ? null : deminimis_limit.attributes.id,
              deminimis_limit: isEmpty(deminimis_limit) ? [] : deminimis_limit.attributes.value,
              id_shipping_term: isEmpty(shipping_term) ? null : shipping_term.attributes.id,
              shipping_term: isEmpty(shipping_term) ? 0 : shipping_term.attributes.value,
              id_handling_charges: isEmpty(handling_charges) ? null : handling_charges.attributes.id,
              handling_charges: isEmpty(handling_charges) ? 0 : handling_charges.attributes.value,
              official_routes: isEmpty(official_routes) ? [] : official_routes,
              id_default_shipment_method: isEmpty(default_shipment_method) ? null : default_shipment_method.attributes.id,
              shipment_source_country_id: isEmpty(default_shipment_method) ? null : default_shipment_method.attributes.source_country_id,
              default_shipment_method: isEmpty(default_shipment_method) ? null : default_shipment_method.attributes.value,
              id_exclude_deminimis_category: isEmpty(exclude_deminimis_category) ? null : exclude_deminimis_category.attributes.id,
              exclude_deminimis_category: isEmpty(exclude_deminimis_category) ? null : JSON.parse(exclude_deminimis_category.attributes.value),
              
              id_official_weight_step: isEmpty(official_weight_step) ? null : official_weight_step.attributes.id,
              official_weight_step: isEmpty(official_weight_step) ? 0 : official_weight_step.attributes.value,
              id_official_min_weight: isEmpty(official_min_weight) ? null : official_min_weight.attributes.id,
              official_min_weight: isEmpty(official_min_weight) ? 0 : official_min_weight.attributes.value,
              id_official_cbm_step: isEmpty(official_cbm_step) ? null : official_cbm_step.attributes.id,
              official_cbm_step: isEmpty(official_cbm_step) ? 0 : official_cbm_step.attributes.value,
              id_official_min_cbm: isEmpty(official_min_cbm) ? null : official_min_cbm.attributes.id,
              official_min_cbm: isEmpty(official_min_cbm) ? 0 : official_min_cbm.attributes.value,
              id_unofficial_weight_step: isEmpty(unofficial_weight_step) ? null : unofficial_weight_step.attributes.id,
              unofficial_weight_step: isEmpty(unofficial_weight_step) ? 0 : unofficial_weight_step.attributes.value,
              id_unofficial_min_weight: isEmpty(unofficial_min_weight) ? null : unofficial_min_weight.attributes.id,
              unofficial_min_weight: isEmpty(unofficial_min_weight) ? 0 : unofficial_weight_step.attributes.value,
              id_unofficial_cbm_step: isEmpty(unofficial_cbm_step) ? null : unofficial_cbm_step.attributes.id,
              unofficial_cbm_step: isEmpty(unofficial_cbm_step) ? 0 : unofficial_cbm_step.attributes.value,
              id_unofficial_min_cbm: isEmpty(unofficial_min_cbm) ? null : unofficial_min_cbm.attributes.id,
              unofficial_min_cbm: isEmpty(unofficial_min_cbm) ? 0 : unofficial_min_cbm.attributes.value,

              id_official_trucking_currency: isEmpty(official_trucking_currency) ? null : official_trucking_currency.attributes.id,
              official_trucking_currency: isEmpty(official_trucking_currency) ? null : official_trucking_currency.attributes.value,
              id_official_trucking_cost: isEmpty(official_trucking_cost) ? null : official_trucking_cost.attributes.id,
              official_trucking_cost: isEmpty(official_trucking_cost) ? 0 : official_trucking_cost.attributes.value,
              id_official_min_trucking: isEmpty(official_min_trucking) ? null : official_min_trucking.attributes.id,
              official_min_trucking: isEmpty(official_min_trucking) ? 0 : official_min_trucking.attributes.value,
              id_official_trucking_step: isEmpty(official_trucking_step) ? null : official_trucking_step.attributes.id,
              official_trucking_step: isEmpty(official_trucking_step) ? 0 : official_trucking_step.attributes.value,

              id_unofficial_trucking_currency: isEmpty(unofficial_trucking_currency) ? null : unofficial_trucking_currency.attributes.id,
              unofficial_trucking_currency: isEmpty(unofficial_trucking_currency) ? null : unofficial_trucking_currency.attributes.value,
              id_unofficial_trucking_cost: isEmpty(unofficial_trucking_cost) ? null : unofficial_trucking_cost.attributes.id,
              unofficial_trucking_cost: isEmpty(unofficial_trucking_cost) ? 0 : unofficial_trucking_cost.attributes.value,
              id_unofficial_min_trucking: isEmpty(unofficial_min_trucking) ? null : unofficial_min_trucking.attributes.id,
              unofficial_min_trucking: isEmpty(unofficial_min_trucking) ? 0 : unofficial_min_trucking.attributes.value,
              id_unofficial_trucking_step: isEmpty(unofficial_trucking_step) ? null : unofficial_trucking_step.attributes.id,
              unofficial_trucking_step: isEmpty(unofficial_trucking_step) ? 0 : unofficial_trucking_step.attributes.value,

              promo_air_by_role: isEmpty(official_weight_cost) ? null : Number(official_weight_cost.attributes.role_ids),
              promo_air_dtd_by_role: isEmpty(unofficial_weight_cost) ? null : Number(unofficial_weight_cost.attributes.role_ids),
              promo_ocean_by_role: isEmpty(official_cbm_cost) ? null : Number(official_cbm_cost.attributes.role_ids),
              promo_ocean_dtd_by_role: isEmpty(unofficial_cbm_cost) ? null : Number(unofficial_cbm_cost.attributes.role_ids),
              promo_official_trucking_by_role: isEmpty(official_trucking_cost) ? null : Number(official_trucking_cost.attributes.role_ids),
              promo_unofficial_trucking_by_role: isEmpty(unofficial_trucking_cost) ? null : Number(unofficial_trucking_cost.attributes.role_ids),
              status_promo_air_by_role: isEmpty(status_promo_air_by_role) ? 'inactive' : status_promo_air_by_role.attributes.value,
              status_promo_ocean_by_role: isEmpty(status_promo_ocean_by_role) ? 'inactive' : status_promo_ocean_by_role.attributes.value,
              status_promo_air_dtd_by_role: isEmpty(status_promo_air_dtd_by_role) ? 'inactive' : status_promo_air_dtd_by_role.attributes.value,
              status_promo_ocean_dtd_by_role: isEmpty(status_promo_ocean_dtd_by_role) ? 'inactive' : status_promo_ocean_dtd_by_role.attributes.value,
              status_promo_official_trucking_by_role: isEmpty(status_promo_official_trucking_by_role) ? 'inactive' : status_promo_official_trucking_by_role.attributes.value,
              status_promo_unofficial_trucking_by_role: isEmpty(status_promo_unofficial_trucking_by_role) ? 'inactive' : status_promo_unofficial_trucking_by_role.attributes.value,
              air: air,
              ocean: ocean,
              air_dtd: air_dtd,
              ocean_dtd: ocean_dtd,
              air_courier: air_courier,
              official_trucking: official_trucking,
              unofficial_trucking: unofficial_trucking,
              relation_cbm_cost: cbmcost,
              relation_weight_cost: weightcost,
              relation_un_cbm: uncbmcost,
              relation_un_weight: unweightcost,
              relation_trucking_cost: truckingcost,
              relation_un_trucking: untruckingcost
            }
          }
          this.expandSelectCountryCurrency = country.nameCountryCurrency
          this.tableItems.push(country)
        }
        // console.log(this.tableItems, 'items')
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getOriginCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemOriginCountry = []
      for (const country of responseData) {
        this.itemOriginCountry.push(country.attributes)
      }
      this.itemOriginCountry = sortBy(this.itemOriginCountry, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  async getDestinationCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemDestinationCountry = []
      for (const country of responseData) {
        this.itemDestinationCountry.push(country.attributes)
      }
      this.itemDestinationCountry = sortBy(this.itemDestinationCountry, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  async getDestinationCountry() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemDestinationName = []
      for (const country of responseData) {
        this.itemDestinationName.push(country.attributes)
      }
      this.itemDestinationName = sortBy(this.itemDestinationName, 'name')
      this.internationalDataDialog.destination_id = this.internationalDataDialog.destination_country_id
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  async getOriginCities() {
    this.getCityLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.internationalDataDialog.origin_country_id
        }
      }
      if (!isEmpty(this.itemOriginKeywords)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.itemOriginKeywords
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CityService.getCities(opts)
      const responseData = response.data.data
      this.itemOriginName = []
      for (const city of responseData) {
        // this.itemOriginName.push(city.attributes)
        this.itemOriginName.push({
          name: city.attributes.name,
          id: city.attributes.id
        })
      }
      this.itemOriginName = sortBy(this.itemOriginName, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCityLoading = false
    }
  }

  async getOriginProvinces() {
    this.getProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.internationalDataDialog.origin_country_id
        }
      }
      if (!isEmpty(this.itemOriginKeywords)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.itemOriginKeywords
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await ProvinceService.getProvinces(opts)
      const responseData = response.data.data
      this.itemOriginName = []
      for (const province of responseData) {
        // this.itemOriginName.push(province.attributes)
        this.itemOriginName.push({
          name: province.attributes.name,
          id: province.attributes.id
        })
      }
      this.itemOriginName = sortBy(this.itemOriginName, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getProvinceLoading = false
    }
  }

  async enableRole(item) {
    if(item.data.promo_air_by_role)
      try {
        var checkAvailableShipmentMethod = [
          'air', 'ocean', 'air_dtd', 'ocean_dtd', 
          'official_trucking','unofficial_trucking'
        ]
        forEach(checkAvailableShipmentMethod, async (shipment) => {
          if(this.enable_rate[shipment] == true){
            const enable = {
              shipment_method : shipment,
              country_id: item.id,
              source_country_id: this.filter.from.selected.value
            }
            await SettingsService.enable(enable).then(response => {
            // this.showSnackbar({
            //   text: 'Please choose the role member', 
            //   color: 'green', 
            //   timeout: 2500
            // })
            })
          }
        })
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
        this.getCountry()
      }
  }

  async disableRole(item,index,key) {
    // console.log(item, 'item disable');
    try {
      var checkAvailableShipmentMethod = [
        'air', 'ocean', 'air_dtd', 'ocean_dtd', 
        'official_trucking','unofficial_trucking'
      ]
      forEach(checkAvailableShipmentMethod, async(shipment) => {
        if(this.enable_rate[shipment] == false){
          const disable = {
            shipment_method : shipment,
            country_id: item.id,
            source_country_id: this.filter.from.selected.value
          }
          await SettingsService.disable(disable).then(response => {
            // this.showSnackbar({
            //   text: 'Inactive', 
            //   color: 'green', 
            //   timeout: 1000
            // })
            // if(response.data.success) {
            //   this.tableItems[index].data[key] = 0
            // }
          })
        }
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
      this.getCountry()
    }
  }

  async updateSettings(item) {
    const validationResponse = await this.$validator.validateAll('data')
    if (validationResponse) {
      this.showLoading({ text: 'Saving Data...' })
      try {
        const payload_shipment_delete = {
          data: {
            data: [
            ]
          }
        }

        const payload_create = {
          data: []
        }

        const payload_edit = {
          data: []
        }

        var checkAvailableShipmentMethod = [
          'air', 'ocean', 'air_dtd', 'ocean_dtd', 
          'air_courier', 'official_trucking',
          'unofficial_trucking'
        ]

        var new_rute = []
        forEach(this.newRutes, rute => {
          if (rute.hasOwnProperty('new_rute_id')) {
            var set_new_rute = {
              'country': rute.name,
              'rute': 'custom-optional'
            }
            new_rute.push(set_new_rute)
          }
        })

        forEach(checkAvailableShipmentMethod, arrShipmentMethod => {
          if (item.data[arrShipmentMethod]) {
            if (isEmpty(find(item.data.available_shipment_methods, data => data.attributes.value === arrShipmentMethod))) {
              var set_item_create = {
                attributes: {
                  key: 'available_shipment_methods',
                  country_id: item.id,
                  source_country_id: this.filter.from.selected.value,
                  value: arrShipmentMethod
                }
              }
              payload_create.data.push(set_item_create)
            }
          } else {
            if (!isEmpty(find(item.data.available_shipment_methods, data => data.attributes.value === arrShipmentMethod))) {
              const data = find(item.data.available_shipment_methods, data => data.attributes.value === arrShipmentMethod)
              payload_shipment_delete.data.data.push({
                id: data.attributes.id
              })
            }
          }
        })

        var checkArr = [
          'official_cbm_cost',
          'official_cbm_currency',
          // 'official_routes',
          'official_vat',
          'official_weight_cost',
          'official_weight_currency',
          'unofficial_cbm_cost',
          'unofficial_cbm_currency',
          'unofficial_weight_cost',
          'unofficial_weight_currency',
          'deminimis_currency',
          'deminimis_limit',
          'shipping_term',
          'default_shipment_method',
          'handling_charges',
          // 'exclude_deminimis_category',
          'official_weight_step',
          'official_min_weight', 
          'official_cbm_step', 
          'official_min_cbm',
          'unofficial_weight_step', 
          'unofficial_min_weight',
          'unofficial_cbm_step',
          'unofficial_min_cbm',

          'official_trucking_cost',
          'official_trucking_currency',
          'official_trucking_step',
          'official_min_trucking',
          'unofficial_trucking_cost',
          'unofficial_trucking_currency',
          'unofficial_trucking_step',
          'unofficial_min_trucking'
        ]
        
        // set replace deminimis
        var deminimis_limit_to_string = JSON.stringify(item.data.deminimis_limit)
        item.data.deminimis_limit = deminimis_limit_to_string
        forEach(checkArr, arr => {
          if (arr === 'exclude_deminimis_category') {
            item.data[arr] = JSON.stringify(map(item.data[arr], 'id'))
          }

          if (item.data['id_' + arr] === null) {
            var set_item_create: any = {
              attributes: {
                key: arr,
                country_id: item.id,
                source_country_id: this.filter.from.selected.value,
                value: item.data[arr]
              }
            }
            if (arr === 'exclude_deminimis_category') {
              set_item_create.attributes.source_country_id = null
            }
            if (arr === 'official_weight_cost') {
              set_item_create.attributes.role_ids = item.data.promo_air_by_role
            }
            if (arr === 'official_cbm_cost') {
              set_item_create.attributes.role_ids = item.data.promo_ocean_by_role
            }
            if (arr === 'official_trucking_cost') {
              set_item_create.attributes.role_ids = item.data.promo_official_trucking_by_role
            }
            if (arr === 'unofficial_weight_cost') {
              set_item_create.attributes.role_ids = item.data.promo_air_dtd_by_role
            }
            if (arr === 'unofficial_cbm_cost') {
              set_item_create.attributes.role_ids = item.data.promo_ocean_dtd_by_role
            }
            if (arr === 'unofficial_trucking_cost') {
              set_item_create.attributes.role_ids = item.data.promo_unofficial_trucking_by_role
            }
            payload_create.data.push(set_item_create)
          } else {
            var set_item_update: any = {
              id: item.data['id_' + arr],
              attributes: {
                key: arr,
                country_id: item.id,
                source_country_id: this.filter.from.selected.value,
                value: item.data[arr]
              }
            }
            if (arr === 'deminimis_currency') {
              set_item_update.attributes.source_country_id = null
            }
            if (arr === 'deminimis_limit') {
              set_item_update.attributes.source_country_id = null
            }
            if (arr === 'shipping_term') {
              set_item_update.attributes.source_country_id = null
            }
            if (arr === 'exclude_deminimis_category') {
              set_item_update.attributes.source_country_id = null
            }
            if (arr === 'exclude_deminimis_category') {
              set_item_update.attributes.source_country_id = null
            }
            if (arr === 'official_weight_cost') {
              set_item_update.attributes.role_ids = item.data.promo_air_by_role
            } 
            if (arr === 'official_cbm_cost') {
              set_item_update.attributes.role_ids = item.data.promo_ocean_by_role
            }
            if (arr === 'official_trucking_cost') {
              set_item_update.attributes.role_ids = item.data.promo_official_trucking_by_role
            }
            if (arr === 'unofficial_weight_cost') {
              set_item_update.attributes.role_ids = item.data.promo_air_dtd_by_role
            }
            if (arr === 'unofficial_cbm_cost') {
              set_item_update.attributes.role_ids = item.data.promo_ocean_dtd_by_role
            }
            if (arr === 'unofficial_trucking_cost') {
              set_item_update.attributes.role_ids = item.data.promo_unofficial_trucking_by_role
            }
            payload_edit.data.push(set_item_update)
          }
        })
        // console.log('payload_create', payload_create)
        const responseCreate = await SettingsService.createSettings(payload_create)
        // console.log('payload_edit', payload_edit)
        const responseEdit = await SettingsService.updateSettings(payload_edit)
        // console.log('payload_shipment_delete', payload_shipment_delete)
        const responseDelete = await SettingsService.deleteSettings(payload_shipment_delete)
        //     await SettingsService.updateSettings(payload)
        await this.wholesalePrice(item)
        this.showSnackbar({ text: 'Saved Successfully!', color: 'green', timeout: 2500 })
        this.closeExpand()
      } catch (error) { 
        this.showSnackbar({ error, text: 'Field Currency Must Be Filled!', color: 'red', timeout: 2000 })
      } finally {
        this.closeLoading()
        this.getCountry()
      }
    } else
      this.showSnackbar({
        text: 'Please check all field(s) requirements',
        color: 'primary',
        timeout: 2000
      })
  }

  searchFilterChanged(newData: any) {
    this.tablePagination.page = 1
    this.searchFilterParamsCountryKeyword = newData.search
    this.getCountry()
  }

  openCreateInternational(propsCountry){
    this.titleDialog = 'Add New Rates'
    this.dataRates = []
    this.internationalDataDialog = {
      shipping_types: 'International',
      shipping_method: 'Courier',
      courier_id: '',
      origin_country_id: this.filter.from.selected.value,
      origin_id: '',
      origin_type: 'Cities',
      destination_country_id: propsCountry.item.id,
      destination_id: '',
      destination_type: 'Country',
      rate: '',
      currency: '',
      rate_per_weight: '',
      weight_unit: 'Kg',
      minimum: '',
      maximum: '',
      calculation: 'CUSTOM',
      remarks: '',
      name: '',
      insurance_percentage: '0'
    }
    this.insurance = false
    this.getDestinationName()
    this.internationalDialog = true
    this.useAsEdit = false
    this.inputCourierItem = false
  }

  async getUpdate(propsEdit){
    const opts: any = {
      params: {
        diverse: 'International',
        'filter[origin_country_id][is]' : propsEdit.item.origin_country_id,
        'filter[destination_country_id][is]' : propsEdit.item.destination_country_id,
        'filter[origin_id][is]' : propsEdit.item.origin_id,
        'filter[destination_id][is]' : propsEdit.item.destination_id
      }
    }
    try {
      this.tableLoading = true
      this.tableItemsUpdate = []
      const response: any = await DomesticService.getDomestic(opts)
      opts.params['page[limit]'] = response.data.meta.pagination.total
      const responseEdit: any = await DomesticService.getDomestic(opts)
      for (let dataDetails of responseEdit.data.data){
        this.tableItemsUpdate.push(dataDetails.attributes)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  } 

  async openEditInternational(propsEdit){
    await this.getUpdate(propsEdit)
    this.dataRates = []
    for(let rateUpdate of this.tableItemsUpdate){
      this.dataRates.push({
        id: rateUpdate.id,
        rate: rateUpdate.rate,
        rate_per_weight: rateUpdate.rate_per_weight
      })
    }
    this.titleDialog = 'Edit Rates'
    this.internationalDataDialog = {
      shipping_types: startCase(propsEdit.item.shipping_types),
      shipping_method: startCase(propsEdit.item.shipping_method),
      courier_id: propsEdit.item.courier_id,
      origin_country_id: propsEdit.item.origin_country_id,
      origin_id: propsEdit.item.origin_id,
      origin_type: startCase(propsEdit.item.origin_type),
      destination_country_id: propsEdit.item.destination_country_id,
      destination_id: propsEdit.item.destination_id,
      destination_type: startCase(propsEdit.item.destination_type),
      // rate: props.item.rate,
      currency: propsEdit.item.currency,
      // rate_per_weight: props.item.rate_per_weight,
      weight_unit: startCase(propsEdit.item.weight_unit),
      minimum: propsEdit.item.minimum,
      maximum: propsEdit.item.maximum,
      calculation: upperCase(propsEdit.item.calculation),
      remarks: propsEdit.item.remarks,
      name: propsEdit.item.name,
      insurance_percentage: propsEdit.item.insurance*100
    }
    this.insurance = propsEdit.item.insurance ? true : false
    // if(propsEdit.item.insurance === 1){
    //   this.insurance = true
    // } else {
    //   this.insurance = false
    // }
    this.itemCurrencies.push(propsEdit.item.currency)
    this.itemCourier.push({
      name: propsEdit.item.courierName,
      id: propsEdit.item.courier_id
    })
    this.itemOriginName.push({
      name: propsEdit.item.originName,
      id: propsEdit.item.origin_id
    })
    this.itemDestinationName.push({
      name: propsEdit.item.destinationName,
      id: propsEdit.item.destination_id
    })
    this.selectedEditId = propsEdit.item.id
    this.useAsEdit = true
    this.internationalDialog = true
    this.inputCourierItem = false
  }

  openDeleteModal(props) {
    this.selectedInternational = props.item
    this.showDeleteModal = true
  }

  async addInternationalRates() {
    try{
      const validationResponse = await this.$validator.validateAll('addNewRatesInternational')
      if (validationResponse) {
        this.showLoading({ text: 'Saving...' })
        this.internationalDialog = false
        // var payload_insurance = 0
        // if(this.insurance === true){
        //   payload_insurance = 1
        // }
        // var split = this.internationalDataDialog.insurance_percentage.split('%')
        for(const rates of this.dataRates) {
          const internationalDataDialog = {
            shipping_types: this.internationalDataDialog.shipping_types,
            shipping_method: lowerCase(this.internationalDataDialog.shipping_method),
            courier_id: this.internationalDataDialog.courier_id,
            origin_country_id: this.internationalDataDialog.origin_country_id,
            origin_id: this.internationalDataDialog.origin_id,
            origin_type: lowerCase(this.internationalDataDialog.origin_type),
            destination_country_id: this.internationalDataDialog.destination_country_id,
            destination_id: this.internationalDataDialog.destination_id,
            destination_type: this.internationalDataDialog.destination_type,
            rate: rates.rate,
            currency: this.internationalDataDialog.currency,
            rate_per_weight: rates.rate_per_weight,
            weight_unit: lowerCase(this.internationalDataDialog.weight_unit),
            minimum: this.internationalDataDialog.minimum,
            maximum: this.internationalDataDialog.maximum,
            calculation: lowerCase(this.internationalDataDialog.calculation),
            remarks: this.internationalDataDialog.remarks,
            name: this.internationalDataDialog.name,
            insurance: this.insurance ? this.internationalDataDialog.insurance_percentage/100 : 0,
            // insurance_percentage: this.internationalDataDialog.insurance_percentage,
            relationships: []
          }
          if (this.internationalDataDialog.courier_id === 'oth') {
            internationalDataDialog.relationships = [
              {
                type: 'courier',
                courier_name: this.inputInternationalCourierNew
              }
            ]
          }
          await DomesticService.createDomestic(internationalDataDialog)
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
        }
        this.closeExpand()
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
      this.getCountry()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async updateInternationalRates() {
    try {
      const validationResponse = await this.$validator.validateAll('addNewInternational')
      if (validationResponse) {
        this.showLoading({ text: 'Saving...' })
        // var payload_insurance = 0
        // if(this.insurance === true){
        //   payload_insurance = 1
        // }
        // var split = this.internationalDataDialog.insurance_percentage.split('%')
        forEach(this.dataRates, async(rates, index) => {
          const internationalDataDialog = {
            shipping_types: this.internationalDataDialog.shipping_types,
            shipping_method: this.internationalDataDialog.shipping_method,
            courier_id: this.internationalDataDialog.courier_id,
            origin_country_id: this.internationalDataDialog.origin_country_id,
            origin_id: this.internationalDataDialog.origin_id,
            origin_type: this.internationalDataDialog.origin_type,
            destination_country_id: this.internationalDataDialog.destination_country_id,
            destination_id: this.internationalDataDialog.destination_id,
            destination_type: this.internationalDataDialog.destination_type,
            rate: rates.rate,
            currency: this.internationalDataDialog.currency,
            rate_per_weight: rates.rate_per_weight,
            weight_unit: this.internationalDataDialog.weight_unit,
            minimum: this.internationalDataDialog.minimum,
            maximum: this.internationalDataDialog.maximum,
            calculation: this.internationalDataDialog.calculation,
            remarks: this.internationalDataDialog.remarks,
            name: this.internationalDataDialog.name,
            insurance: this.insurance ? this.internationalDataDialog.insurance_percentage/100 : 0,
            // insurance_percentage: this.internationalDataDialog.insurance_percentage,
            relationships: []
          }
          if (this.internationalDataDialog.courier_id === 'oth') {
            internationalDataDialog.relationships = [
              {
                type: 'courier',
                courier_name: this.inputInternationalCourierNew
              }
            ]
          }
          
          // console.log(internationalDataDialog, 'edit')
          if(rates.id == null){
            await DomesticService.createDomestic(internationalDataDialog)
          } else{
            await DomesticService.editDomestic(rates.id, internationalDataDialog)
          }
        })
        this.showSnackbar({
          text: 'Saved Successfully!',
          color: 'green',
          timeout: 1500
        })
        this.useAsEdit = false
        this.internationalDialog = false
      }
      this.getCountry()
      this.closeExpand()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async deleteInternational() {
    try {
      const payload = {
        id: [this.selectedInternational.id],
        action: 'delete'
      }
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false
      const response = await DomesticService.deleteDomestic(this.selectedInternational.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.getCountry()
      this.closeExpand()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  onNewAddCourier() {
    if (this.internationalDataDialog.courier_id == 'oth') {
      this.inputCourierItem = true
    } else {
      this.inputCourierItem = false
    }
  }

  toggleAll() {
    if (this.checkboxAllSelected === true) {
      forEach(this.itemsInternational, international => {
        this.dataDomesticAllSelected = international
        this.selectedId.push(this.dataDomesticAllSelected.idGroup)
      })
    } else {
      this.selectedId = []
    }
  }

  bulkDeleteButton() {
    this.modalBulkDelete = true
  }

  async bulkDeleteInternational() {
    try {
      let selectedPayload = []
      if(this.selectedId.length > 1){
        forEach(this.selectedId, select => {
          selectedPayload = selectedPayload.concat(select)
        })
      }else{
        selectedPayload = this.selectedId[0]
      }
      const payload = {
        data: { ids: selectedPayload }
      }
      this.showLoading({ text: 'Saving...' })
      this.modalBulkDelete = false
      const response = await DomesticService.bulkDelete(payload)
      this.showSnackbar({
        text: 'Success',
        color: 'green',
        timeout: 1500
      })
      this.getCountry()
      this.closeExpand()
      this.selectedId = []
      this.checkboxAllSelected = false
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openImportData(props) {
    this.importDataDialog = {
      shipping_type: 'International',
      origin_type: '',
      destination_type: '',
      country_id: props.item.id
    }
    this.modalImportData = true
  }

  closeImportData(){
    this.modalImportData = false
    ;(this.$refs as any).resetData.value = ''
  }

  importData(e, index) {
    const file = e.target.files[0]
    this.modelImportData = file
  }

  async attachExcel(){
    try {
      const validationResponse = await this.$validator.validateAll('importData')
      if (validationResponse) {
        this.showLoading({ text: 'Saving...' })
        let configfile = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
            Identifier: 'manage'
          }
        }
        let data = new FormData()
        data.append('import_data', this.modelImportData)
        data.append('origin_type', lowerCase(this.importDataDialog.origin_type))
        data.append('destination_type', lowerCase(this.importDataDialog.destination_type))
        data.append('shipping_type', lowerCase(this.importDataDialog.shipping_type))
        data.append('country_id', lowerCase(this.importDataDialog.country_id))
        await DomesticService.importData(data, configfile)
        this.showSnackbar({
          text: 'Saved Successfully!',
          color: 'green',
          timeout: 1500
        })
        this.modalImportData = false
      }
      this.getCountry()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}
