import BaseService from '@/services/BaseService'

class CurrencyService extends BaseService {
  constructor() {
    super()
  }

  // Get All Currencies
  async getCurrencies(opts = {}) {
    this.endPoint = 'api/v2/currency'
    return await this.get(opts)
  }

  // Add and Edit Currencies
  async saveCurrency(payload, currencyId = '') {
    this.endPoint = 'api/v2/currency'
    
    if (currencyId === ''){
      return this.post(payload)
    } else {
      return this.putOne(currencyId,payload)
    }
  }

  // Delete Currencies
  async deleteCurrency(currencyId) {
    this.endPoint = 'api/v2/currency'
    return this.delete(currencyId)
  }

}

export default new CurrencyService()
